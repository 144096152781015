$product-sticky-bar-add-bag-width: 100%;
$product-sticky-bar-box-shadow: rem-calc(0 16 20 0) rgba(color(dark), 0.1);
$product-sticky-bar-border-bottom: none;
$product-sticky-bar-item-image-border: none;
$product-sticky-bar-title-font-size: rem-calc(18);
$product-sticky-bar-title-font-weight: 500;
$product-sticky-bar-title-text-transform: uppercase;
$product-sticky-bar-description-color: color(organism-background);
$product-sticky-bar-description-font-size: rem-calc(14);
$product-sticky-bar-description-margin: rem-calc(5 0 0);
$product-sticky-bar-wishlist-link-font-weight: 500;
$product-sticky-bar-item-variation-flex: 0;
$product-sticky-bar-item-variation-margin: 0;
$product-sticky-bar-item-variation-dropdown-width: rem-calc(170);
$product-sticky-bar-item-variation-section-flex: 0 1 0;
$product-sticky-bar-item-variation-section-margin: rem-calc(0);
$product-sticky-bar-item-actions-width--large: 44%;
$product-sticky-bar-price-margin: rem-calc(0 40 0 0);
$product-sticky-bar-padding: rem-calc(10);
$product-sticky-bar-where-to-buy-margin: 0 0 0 16%;
$product-sticky-bar-item-variation-min-width: rem-calc(344);

@import "@lora/05-components/product/product-sticky-bar";