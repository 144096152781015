$product-variations-carousel-link-selected-background: color(selected);
$product-variations-carousel-link-font: 100 #{rem-calc(14)} / 1 $font-primary;
$product-variations-carousel-link-height: rem-calc(68);
$product-variations-carousel-link-padding: rem-calc(0 20);
$product-variations-carousel-link-min-width--large: rem-calc(130);
$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-value-white-space: normal;
$product-variations-carousel-link-border: 1px solid color(dark);
// Selected state
$product-variations-carousel-link-selected-background: color(global-background);
$product-variations-carousel-link-hover-border: 2px solid color(primary);
$product-variations-carousel-link-hover-color: color(primary);

@import "@lora/05-components/product/product-variations-carousel";