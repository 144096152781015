$service-item-min-width: 85vw;

@mixin master-components-product-services {
    .c-service.m-swipable {
        .c-service__item {
            @include breakpoint(medium down) {
                min-width: $service-item-min-width;
            }
        }
    }
}

@import "@lora/05-components/service"; //stylelint-disable-line no-invalid-position-at-import-rule