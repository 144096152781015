$product-availability-display: inline-flex;
$product-availability-label-text-transform: none;
$product-availability-cart-item-label-font: 500 #{rem-calc(16)} / 1.25 $font-primary;
$product-availability-cart-item-label-font--large: $product-availability-cart-item-label-font;
$product-availability-cart-item-label-text-transform: none;
$product-availability-estimated-delivery-date-text-transform: $product-availability-label-text-transform;
$product-availability-label-font--large: 100 #{rem-calc(14)} / 1.25 $font-primary;
$product-availability-estimated-delivery-date-font--large: 100 #{rem-calc(14)} / 1.25 $font-primary;
$product-availability-label-font: 100 #{rem-calc(16)} / 1.25 $font-primary;
$product-availability-estimated-delivery-date-font: $product-availability-label-font;
$product-availability-block-display--medium-down: flex;

@import "@lora/05-components/product/product-availability";