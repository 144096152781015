$product-perfect-match-tile-content-position--large: relative;
$product-perfect-match-tile-content-bottom: 0;
$product-perfect-match-tile-content-bottom--large: $product-perfect-match-tile-content-bottom;

/// Tile name
$product-perfect-match-tile-name-font: 500 #{rem-calc(16)} / #{rem-calc(12)} $font-primary;
$product-perfect-match-tile-name-font--large: $product-perfect-match-tile-name-font;
$product-perfect-match-tile-name-margin: rem-calc(20 0 28);
$product-perfect-match-tile-name-margin--large: rem-calc(24 0 28s);

@import "@lora/05-components/product/product-perfect-match";