$product-main-name-font--large: bold #{rem-calc(24)} / 1 $font-tertiary;
$product-main-name-text-transform: uppercase;
$product-main-subtitle-font--large: 300 #{rem-calc(16)} / 1.25 $font-primary;
$product-main-name-margin: rem-calc(0 0 4);
$product-main-name-margin--large: rem-calc(0 0 8);
$product-main-rating-margin: rem-calc(16 0 16);
$product-main-rating-margin--large: rem-calc(0 0 16);
$product-main-image-position: relative;
$product-main-quantity-margin--large: rem-calc(0 30 0 0);
$product-main-name-font: bold #{rem-calc(24)} / 1 $font-tertiary;
$product-main-variations-dropdown-width: 100%;
$product-main-subtitle-margin: rem-calc(5 0 15);
$product-main-aside-background-color--large: color(transparent);
$product-main-short-description-color: color(organism-background);
$product-main-short-description-font: 100 #{rem-calc(16)} / 1 $font-primary;
$product-main-short-description-margin: rem-calc(0 0 50);
$product-main-aside-padding--large: rem-calc(0 0 0 115);
$product-main__more-shade-order: 3;
$product-main__more-shade-width: 36%;
$product-main__more-shade-width--large: 22%;
$product-main-short-description-readmore-font: 500 #{rem-calc(14)} / 1 $font-primary;
$product-main-padding--large: rem-calc(65 0 0);
$product-main-actions-margin: rem-calc(0 0 15);
$product-main-border: none;
$product-main-variations-dropdown-order: 2;
$product-main-review-link-text-decoration: none;
$product-main-review-link-color: color(organism-background);
$product-main-review-font: #{rem-calc(14)} / 1 $font-primary;
$product-main-price-font: 500 #{rem-calc(18)} / 1.4 $font-primary;
$product-main-price-font--large: 500 $product-main-price-font;
$product-main-services-border-top: none;
$product-main-services-border-top--large: $product-main-services-border-top;
$product-main-services-border-bottom: none;
$product-main-services-border-bottom--large: $product-main-services-border-bottom;
$product-main-recommendation-title-font: #{rem-calc(18)} / 1.5 $font-tertiary;
$product-main-recommendation-title-font--large: #{rem-calc(30)} / 1.25 $font-tertiary;
$product-main-where-to-buy-margin--large: 0;
$product-main-icons-top: 0;
$product-main-icons-right: rem-calc(11);

@import "@lora/05-components/product/product-main";