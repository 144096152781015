$product-offer-background: color(global-background);
$product-offer-title-font: #{rem-calc(16)} / 1.125 $font-primary;
$product-offer-title-text-transform: uppercase;
$product-offer-title-font-weight: 500;
$product-offer-margin: rem-calc(40 0 0);
$product-offer-title-font--large: 500 #{rem-calc(18)} / 1 $font-primary;
$product-offer-description-font: 300 #{rem-calc(16)} / 1.25 $font-primary;
$product-offer-padding: rem-calc(0 20 0 0);
$product-offer-icon-width: rem-calc(170);
$product-offer-icon-width--small: rem-calc(80);
$product-offer-icon-padding: 0;
$product-offer-icon-margin: rem-calc(0 20 0 0);
$product-offer-description-display: flex;
$product-offer-description-direction: column;
$product-offer-description-justify: center;
$product-offer-description-padding: rem-calc(10 0);
$product-offer-description-p-font-size: rem-calc(14);
$product-offer-description-p-line-height: 1.2;

@mixin master-components-product-offer {
    .c-product-offer__description {
        display: $product-offer-description-display;
        flex-direction: $product-offer-description-direction;
        justify-content: $product-offer-description-justify;
        padding: $product-offer-description-padding;

        @include breakpoint(medium down) {
            p {
                font-size: $product-offer-description-p-font-size;
                line-height: $product-offer-description-p-line-height;
            }
        }
    }

    .c-product-offer__icon {
        margin: $product-offer-icon-margin;

        @include breakpoint(medium down) {
            min-width: $product-offer-icon-width--small;
            width: $product-offer-icon-width--small;
        }
    }
}

@import "@lora/05-components/product/product-offer"; //stylelint-disable-line no-invalid-position-at-import-rule