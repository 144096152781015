$social-share-text-transform: none;
$social-share-item-border--large: 1px solid color(border);
$social-share-item-border-radius--large: 50%;
$social-share-item-padding--large: rem-calc(8 9);
$social-share-first-item-border--large: none;
$social-share-item-margin--large: rem-calc(0 30 0 0);
$social-share-first-item-margin--large: rem-calc(0 10 0 0);
$social-share-icon-color: color(dark);
$social-share-icon-hover-color: $social-share-icon-color;
$social-share-icon-active-color: $social-share-icon-color;
$social-share-icon-size: rem-calc(17);
$social-share-caption-font: 300 #{rem-calc(14)} / 1.29 $font-primary;

@import "@lora/05-components/social-share";