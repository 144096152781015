$product-routine-item-margin--medium-dowm: rem-calc(0 0 20 20);
$product-routine-tile-name-margin--medium-dowm: rem-calc(0 0 12);
$product-routine-summary-background--large: color(dark);
$product-routine-description-color: color(light);
$product-routine-description-font: bold #{rem-calc(24)} / 1 $font-primary-bold;
$product-routine-description-text-align: left;
$product-routine-description-text-transform: uppercase;
$product-routine-product-name-color: color(light);
$product-routine-product-name-font: 500 #{rem-calc(16)} / 1.25 $font-primary;
$product-routine-product-name-text-align: left;
$product-routine-product-name-text-transform: uppercase;
$product-routine-product-name-margin: rem-calc(5 10);
$product-routine-item-padding--large: rem-calc(0 30 0 0);

@import "@lora/05-components/product/product-routine";