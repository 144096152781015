$back-to-parent-background: color(global-background);
$back-to-parent-border-bottom: none;
$back-to-parent-border-top: none;
$back-to-parent-secondary-text-transform: none;
$back-to-parent-secondary-font: 100 #{rem-calc(16)} / 1.13 $font-primary;
$back-to-parent-secondary-padding: rem-calc(15 0);
$back-to-parent-secondary-font--large: 100 #{rem-calc(16)} / 1.13 $font-primary;
$back-to-parent-font: 100 #{rem-calc(16)} / 1 $font-primary;
$back-to-parent-icon-height: rem-calc(20);

@import "@lora/05-components/back-to-parent";